import React, { useState } from "react";
import Typed from "react-typed";
import "./Terminal.scss";

import TerminalRight from "./TerminalRight";

const Terminal = () => {
  const [twitter, setTwitter] = useState(2);

  // twitter params
  const urlParams = new URLSearchParams(window.location.search);
  // const oauthToken = urlParams.get("oauth_token");
  const oauthToken = true
  const oauthVerifier = urlParams.get("oauth_verifier");
console.log("a")
  return (
    <div className="w-11/12">
      <div className="flex flex-col-reverse sm:flex-row rounded-b-lg text-white bg-black h-full overflow-hidden">
        <div
          className={` px-3 py-3 w-full text-area  rounded-bl-lg rounded-br-lg sm:rounded-br-none `}
        >
          {/* ************* twitter login type left  ***********/}

          {!oauthToken && (
            <Typed
              strings={[
                "Welcome to Oranginals built by ExtrOrdinals with ♡! <br /> To get started, we need to connect your Twitter account. <br /> Please press enter or click the following button: <span class=text-area-twitter>Connect Twitter</span>",
              ]}
                typeSpeed={20}
              contentType={'html'}
            
            />
          )}

          {/* ************* twitter login after type left  ***********/}
          {/* Thank you! Your Twitter account is now connected. <br /> */}
          {twitter == 2 && oauthToken && (
            <Typed
              strings={[
                "@user:~$cd Extraordinals/Oranginals <br /> @user:~/Extraordinals/Oranginals$ls <br /> oranginals.py <br />Upload Image",
              ]}
                typeSpeed={20}
              contentType={'html'}
            />
          )}

          {/* ************* upload type left  ***********/}

          {twitter == 3 && (
            <Typed strings={[`Uploading Image...`]}   typeSpeed={20}
              contentType={'html'} />
          )}

          {/* ************* upload success type left  ***********/}

          {twitter == 4 && (
            <Typed
              strings={[
                "Processing image... <br /> Changing background to orange... <br /> Saving result... <br /> Done. Your new image is created as download.png",
              ]}
                typeSpeed={20}
              contentType={'html'}
            />
          )}
        </div>
        <TerminalRight
          oauthToken={oauthToken}
          twitter={twitter}
          setTwitter={setTwitter}
        />
      </div>
    </div>
  );
};

export default Terminal;
