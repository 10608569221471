import React from "react";
import TopBar from "../../atom/topBar/TopBar";
import Terminal from "../../atom/terminal/Terminal";

// scss
import "./Home.scss";

// img png
import Logo from "../../Assets/Home/Logo.png";

const Home = () => {
  return (
    <div
      style={{ backgroundColor: "#FF7F27" }}
      className="flex flex-col justify-center items-center h-screen relative"
    >
      <TopBar />
      <Terminal />

      <div className="absolute logo-full-screen flex  justify-end items-center">
      <a
          href="https://twitter.com/ExtrOrdinals"
          target="_blank"
          rel="noopener noreferrer"
        >
        <img
          style={{
            width: "70%",
            height: "70%",
          }}
          src={Logo}
          alt=""
        />
        </a>
      </div>
      <div className="absolute logo-small-screen flex  justify-end items-center">
        <a
          href="https://twitter.com/ExtrOrdinals"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              width: "50%",
              height: "50%",
            }}
            src={Logo}
            alt=""
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
