import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
const TerminalRight = ({ twitter, oauthToken, setTwitter }) => {
  const [previewImage, setPreviewImage] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [completeTerminal, setShowMessage] = useState(true);
  const [taskId, setTaskId] = useState(null);
  // handle view right
  useEffect(() => {
    setShowMessage(false);
    let timer = setTimeout(() => {
      setShowMessage(true);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [twitter]);

  // twitter login

  const handlTwiterLogin = async () => {
    const loginUrl =
      "https://photobgchangeapis-production.up.railway.app/twitter/auth";
    window.open(loginUrl, "_self");
  };

  // file upload photoroom

  const handleFileChange = async (e) => {
    setTwitter(3);
    const file = e.target.files[0];
    const apiKey = "648f121be1da60.42289877";
    const formData = new FormData();

    formData.append("image_file", file);
    formData.append("get_base64", 1);
    formData.append("ecom", 1);

    try {
      const response = await axios.post(
        "https://api.removal.ai/3.0/remove",
        formData,
        {
          headers: {
            "Rm-Token": apiKey,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(progress);
                  },
        }
      );

      if (response.status === 200) {
        setPreviewImage(response.data?.base64);
        setUploadProgress(0);
        setTwitter(4);
      } else {
        console.error("Request failed with status:", response.status);
      }
    } catch (error) {
      console.error("Request failed:", error);
    }
    // const formData = new FormData();
    // formData.append("image_file", file);
    // formData.append("bg_color", "#FF4900");
    // // formData.append("size", "451px");
    // try {
    //   const response = await axios.post(
    //     "https://sdk.photoroom.com/v1/segment",
    //     formData,
    //     {
    //       headers: {
    //         "x-api-key": "d83b907b902a633a9798b7a36c9343e3ac1010ca",
    //         Accept: "application/json",
    //       },
    //       // Configure progress tracking
    //       onUploadProgress: (progressEvent) => {
    //         const progress = Math.round(
    //           (progressEvent.loaded * 100) / progressEvent.total
    //         );
    //         setUploadProgress(progress);
    //       },
    //     }
    //   );
    //   setPreviewImage(response.data.result_b64);
    //   setUploadProgress(0);
    //   setTwitter(4);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const containerStyle = {
    backgroundColor: "#FF7F27",
  };
  const handleDownload = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const image = new Image();

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.fillStyle = "#FF7F27";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0);

      const dataURL = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "download.png";
      link.click();
    };

    image.src = `data:image/png;base64,${previewImage}`;
  };
 
  return (
    <div
      className={`sm:w-8/12 mt-1 ${
        (completeTerminal && !oauthToken) || completeTerminal || twitter == 3
          ? "h-full"
          : "h-0"
      } `}
    >
      {/* ************* twitter login right  ***********/}
      {completeTerminal && !oauthToken ? (
        <div
          style={{ backgroundColor: "#343541" }}
          className=" flex justify-center h-full items-center sm:rounded-br-lg cursor-pointer"
          onClick={() => {
            handlTwiterLogin();
          }}
        >
          <div
            style={{ backgroundColor: "#1DA1F2" }}
            className="w-10/12 flex justify-center items-center text-area-login select-none"
          >
            {"{ Connect Twitter }"}
          </div>
        </div>
      ) : null}

      {/* ************* handle Upload right ***********/}
      {completeTerminal && (
        <>
          {twitter == 2 && oauthToken ? (
            <div
              htmlFor="file"
              style={{
                backgroundColor: "#343541",
              }}
              className=" h-full flex justify-center items-center sm:rounded-br-lg "
            >
              <label
                htmlFor="file"
                style={{
                  backgroundColor: "#13BF3F ",
                }}
                className="w-10/12 flex justify-center items-center text-area-login cursor-pointer"
              >
                {uploadProgress === 0 ? (
                  <>
                    {completeTerminal && (
                      <label htmlFor="file">{"Upload Image "}</label>
                    )}
                  </>
                ) : (
                  `Uploading ${uploadProgress}%`
                )}
              </label>
              <input
                type="file"
                id="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
          ) : null}
        </>
      )}
      {/* ************* show image upload progress right ***********/}

      {twitter == 3 && (
        <div
          style={{
            backgroundColor: "#343541",
          }}
          className=" h-full flex flex-col justify-center items-center sm:rounded-br-lg"
        >
          <div
            style={{ backgroundColor: "#13BF3F" }}
            className="w-10/12 flex justify-center items-center text-area-login"
          >
            {`Uploading ${uploadProgress}%`}
          </div>
        </div>
      )}

      {/* ************* image to preview and download right ***********/}
      {completeTerminal && (
        <>
          {twitter == 4 && (
            <div
              style={{
                backgroundColor: "#343541",
              }}
              className=" h-full flex flex-col justify-center items-center sm:rounded-br-lg"
            >
              <div
                className="w-10/12  flex justify-center items-center text-area-login"
                style={containerStyle}
              
              >
                <img
                  src={`data:image/png;base64,${previewImage}`}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#FF7F27",
                    objectFit: "cover",
                  }}
                />
              </div>
              {/* <a
                href={`data:image/png;base64,${previewImage}`}
                style={{
                  backgroundColor: "#13BF3F ",
                }}
                className="w-10/12 m-2 flex justify-center items-center    download "
                download
              > */}

              <button
                onClick={handleDownload}
                style={{
                  backgroundColor: "#13BF3F ",
                }}
                className="my-3 py-3 w-10/12 m-2 flex justify-center items-center download "
              >
                Download
              </button>
              {/* </a> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TerminalRight;
