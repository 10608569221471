import React from "react";
import "./TopBar.scss";
import shareIcon from "../../Assets/Home/shareIcon.svg";

const TopBar = () => {
  return (
    <div
      style={{
        backgroundColor: "#343541",
      }}
      className="w-11/12 p-2 text-white rounded-t-lg flex justify-between items-center font-roboto top-bar-text-left px-3 py-1"
    >
      <p className="font-roboto">oranginals tool by extrordinals</p>
      <a
        href="https://twitter.com/intent/tweet?text=Orange%20is%20the%20new%20Black%20🟧%0A%0AJust%20changed%20the%20course%20of%20history%20using%20the%20Oranginals%20tool%20created%20by%20%40ExtrOrdinals%0A%0ATry%20it%20out%2C%20don’t%20fade.%20%0Ahttps%3A%2F%2Foranginals.netlify.app%2F"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="flex items-center">
          <img
            style={{
              width: "17px",
            }}
            className="mr-1 "
            src={shareIcon}
            alt=""
          />{" "}
          Share
        </span>
      </a>
    </div>
  );
};

export default TopBar;
